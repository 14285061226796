import axios from "axios";
import qs from "qs";
import { ElLoading, ElMessageBox } from "element-plus";

class Http {
  /**
   * @param {Boolean} loading
   * @param {*} router
   * @returns
   */
  constructor(loading, router) {
    if (!router) {
      alert("error");
      return;
    }

    this.router = router;

    if (process.env.NODE_ENV == "development") {
      axios.defaults.baseURL = "/api"; //测试
    } else {
      axios.defaults.baseURL = "/server/policydispatcher.php";
    }

    // post请求头
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded;charset=UTF-8";
    //设置超时
    axios.defaults.timeout = 30000;

    if (loading) {
      axios.interceptors.request.use(
        (config) => {
          ElLoading.service({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          });
          config.withCredentials = true;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
      axios.interceptors.response.use(
        (response) => {
          ElLoading.service({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          }).close();
          return response;
        },
        (error) => {
          ElLoading.service({
            lock: true,
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
          }).close();
          ElMessageBox.alert({
            title: "错误",
            message: "网络请求失败",
          });
        }
      );
    } else {
      axios.interceptors.request.clear();
      axios.interceptors.response.clear();
    }
  }

  request(params) {
    return new Promise((resolve, reject) => {
      axios({
        data: qs.stringify(params),
        method: "POST",
        responseData: "json",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
      })
        .then((res) => {
          if (res.data.error_code == 0) {
            resolve(res.data);
          } else if (res.data.error_code == -1) {
            ElMessageBox.alert(res.data.error_message, "提示", {
              type: "error",
            }).then(() => {
              this.router.replace({
                path: "/login",
              });
            });
          } else {
            ElMessageBox.alert(res.data.error_message, "提示", {
              type: "error",
            });
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}

export default Http;
