<template>
  <div style="min-height: 100vh; max-height: 100vh; height: 100vh">
    <el-container
      style="min-height: 100vh; max-height: 100vh; height: 100vh"
      v-if="sw < 1280"
    >
      <el-header style="height: 80px; border-bottom: 2px dashed #888">
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div style="width: 50px;">
            <el-button
              type="info"
              circle
              size="large"
              :icon="MoreFilled"
              style="rotate: 90deg"
              @click="showMenuMobile = true"
            >
            </el-button>
          </div>
          <div
            style="
              flex: 2;
              text-align: center;
              font-size: 18px;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ currentHeader }}
          </div>
          <div
            style="
              width: 50px;
              justify-content: flex-end;
              align-items: flex-end;
              display: flex;
            "
          >
            <el-button type="info" circle size="large" :icon="Avatar">
            </el-button>
          </div>
        </div>
      </el-header>
      <el-main style="height: calc(100vh - 160px)">
        <Page direction="column" ref="pagePolicy"></Page>
      </el-main>
      <el-footer
        style="
          height: 80px;
          border-top: 2px dashed #888;
          display: flex;
          justify-content: center;
          align-items: center;
          padding-top: 0px;
          padding-bottom: 0px;
        "
      >
        <div style="display: flex; flex: 1; height: 100%">
          <div
            style="
              width: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-right: 20px;
              border-right: 2px dashed #888;
              box-sizing: border-box;
            "
          >
            <div style="text-align: center; color: #e7a60e" class="bottom-icon">
              <el-icon><Present /></el-icon>
            </div>

            <div style="font-size: 20px; font-weight: bold; color: #e7a60e">
              打赏
            </div>
          </div>

          <div
            style="
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
            "
          >
            <a
              href="https://beian.miit.gov.cn/"
              target="_blank"
              style="text-decoration: none; color: rgb(9, 151, 207)"
              >陕ICP备2024050178号-1</a
            >
          </div>

          <div
            style="
              width: 70px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding-left: 20px;
              border-left: 2px dashed #888;
              box-sizing: border-box;
            "
          >
            <div style="text-align: center; color: #019dfb" class="bottom-icon">
              <el-icon><Edit /></el-icon>
            </div>

            <div style="font-size: 20px; font-weight: bold; color: #019dfb">
              勘误
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>

    <el-container
      style="min-height: 100vh; max-height: 100vh; height: 100vh"
      v-else
    >
      <el-header style="height: 80px; border-bottom: 2px dashed #888">
        <div
          style="
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          "
        >
          <div style="flex: 1"></div>
          <div
            style="
              flex: 2;
              text-align: center;
              font-size: 20px;
              font-weight: bold;
            "
          >
            {{ currentHeader }}
          </div>
          <div
            style="
              flex: 1;
              display: flex;
              justify-content: flex-end;
              align-items: flex-end;
            "
          >
            <el-button type="info" circle size="large" :icon="Avatar">
            </el-button>
          </div>
        </div>
      </el-header>
      <el-container style="height: calc(100vh - 160px)">
        <el-aside width="300px" style="border-right: 2px dashed #888">
          <el-menu>
            <template v-for="item in menu" :key="item">
              <el-menu-item
                @click="menuItemClick"
                v-if="!item.sub"
                name="asdasdasd"
                :index="item.id"
                >{{ item.label }}</el-menu-item
              >
              <el-sub-menu :index="item.id" v-else
                ><template #title>{{ item.label }}</template>
                <template v-for="item1 in item.sub" :key="item1">
                  <el-menu-item
                    @click="menuItemClick"
                    v-if="!item1.sub"
                    :index="item1.id"
                    >{{ item1.label }}</el-menu-item
                  >
                  <el-sub-menu v-else :index="item1.id">
                    <template #title>{{ item1.label }}</template>
                    <template v-for="item2 in item1.sub" :key="item2">
                      <el-menu-item
                        @click="menuItemClick"
                        :index="item2.id"
                        v-if="!item2.sub"
                        >{{ item2.label }}</el-menu-item
                      >
                      <el-sub-menu v-else :index="item2.id">
                        <template #title>{{ item2.label }}</template>
                        <template v-for="item3 in item2.sub" :key="item3">
                          <el-menu-item
                            @click="menuItemClick"
                            :index="item3.id"
                            v-if="!item3.sub"
                            >{{ item3.label }}</el-menu-item
                          >
                          <el-sub-menu v-else :index="item3.id">
                            <template #title>{{ item3.label }}</template>
                            <template v-for="item4 in item3.sub" :key="item4">
                              <el-menu-item
                                @click="menuItemClick"
                                :index="item4.id"
                                v-if="!item4.sub"
                                >{{ item4.label }}</el-menu-item
                              >
                              <el-sub-menu v-else :index="item4.id">
                                <template #title>{{ item4.label }}</template>
                                <template
                                  v-for="item5 in item4.sub"
                                  :key="item5"
                                >
                                  <el-menu-item
                                    @click="menuItemClick"
                                    :index="item5.id"
                                    v-if="!item5.sub"
                                    >{{ item5.label }}</el-menu-item
                                  >
                                  <el-sub-menu v-else :index="item5.id">
                                    <template #title>{{
                                      item5.label
                                    }}</template>
                                  </el-sub-menu>
                                </template>
                              </el-sub-menu>
                            </template>
                          </el-sub-menu>
                        </template>
                      </el-sub-menu>
                    </template>
                  </el-sub-menu>
                </template>
              </el-sub-menu>
            </template>
          </el-menu>
        </el-aside>
        <el-main style="overflow: hidden; overflow-y: scroll">
          <Page ref="pagePolicy"></Page>
        </el-main>
      </el-container>
      <el-footer
        style="
          height: 80px;
          border-top: 2px dashed #888;
          display: flex;
          justify-content: center;
          align-items: center;
        "
        ><a
          href="https://beian.miit.gov.cn/"
          target="_blank"
          style="text-decoration: none; color: rgb(9, 151, 207)"
          >陕ICP备2024050178号-1</a
        ></el-footer
      >
    </el-container>

    <el-drawer direction="ltr" size="80%" v-model="showMenuMobile">
      <el-menu>
        <template v-for="item in menu" :key="item">
          <el-menu-item
            @click="menuItemClick"
            v-if="!item.sub"
            :index="item.id"
            >{{ item.label }}</el-menu-item
          >
          <el-sub-menu :index="item.id" v-else
            ><template #title>{{ item.label }}</template>
            <template v-for="item1 in item.sub" :key="item1">
              <el-menu-item
                @click="menuItemClick"
                v-if="!item1.sub"
                :index="item1.id"
                >{{ item1.label }}</el-menu-item
              >
              <el-sub-menu v-else :index="item1.id">
                <template #title>{{ item1.label }}</template>
                <template v-for="item2 in item1.sub" :key="item2">
                  <el-menu-item
                    @click="menuItemClick"
                    :index="item2.id"
                    v-if="!item2.sub"
                    >{{ item2.label }}</el-menu-item
                  >
                  <el-sub-menu v-else :index="item2.id">
                    <template #title>{{ item2.label }}</template>
                    <template v-for="item3 in item2.sub" :key="item3">
                      <el-menu-item
                        @click="menuItemClick"
                        :index="item3.id"
                        v-if="!item3.sub"
                        >{{ item3.label }}</el-menu-item
                      >
                      <el-sub-menu v-else :index="item3.id">
                        <template #title>{{ item3.label }}</template>
                        <template v-for="item4 in item3.sub" :key="item4">
                          <el-menu-item
                            @click="menuItemClick"
                            :index="item4.id"
                            v-if="!item4.sub"
                            >{{ item4.label }}</el-menu-item
                          >
                          <el-sub-menu v-else :index="item4.id">
                            <template #title>{{ item4.label }}</template>
                            <template v-for="item5 in item4.sub" :key="item5">
                              <el-menu-item
                                @click="menuItemClick"
                                :index="item5.id"
                                v-if="!item5.sub"
                                >{{ item5.label }}</el-menu-item
                              >
                              <el-sub-menu v-else :index="item5.id">
                                <template #title>{{ item5.label }}</template>
                              </el-sub-menu>
                            </template>
                          </el-sub-menu>
                        </template>
                      </el-sub-menu>
                    </template>
                  </el-sub-menu>
                </template>
              </el-sub-menu>
            </template>
          </el-sub-menu>
        </template>
      </el-menu>
    </el-drawer>
  </div>
</template>

<script setup>
import { MoreFilled, Avatar, Edit } from "@element-plus/icons-vue";
</script>

<script>
import Http from "./Http";
import Page from "./views/Page.vue";
export default {
  name: "app",
  data() {
    return {
      showMenuMobile: false,
      sw: 0,
      currentHeader: "",
      menu: [
        {
          id: "法律",
          label: "法律",
        },
        {
          id: "规范性文件",
          label: "规范性文件",
          sub: [
            {
              id: "规范性文件_政府会计制度——行政事业单位会计科目和报表",
              label: "政府会计制度——行政事业单位会计科目和报表",
              sub: [
                {
                  id: "规范性文件_政府会计制度行政事业单位会计科目和报表_目录",
                  label: "目录",
                },
                {
                  id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第一部分总说明",
                  label: "第一部分 总说明",
                },
                {
                  id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号",
                  label: "第二部分 会计科目名称和编号",
                  sub: [
                    {
                      id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目",
                      label: " 一、财务会计科目",
                      sub: [
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目_资产类",
                          label: "（一）资产类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目_负债类",
                          label: "（二）负债类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目_净资产类",
                          label: "（三）净资产类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目_收入类",
                          label: "（四）收入类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_财务会计科目_费用类",
                          label: "（五）费用类",
                        },
                      ],
                    },
                    {
                      id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_预算会计科目",
                      label: " 二、预算会计科目",
                      sub: [
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_预算会计科目_预算收入类",
                          label: "（一）预算收入类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_预算会计科目_预算支出类",
                          label: "（二）预算支出类",
                        },
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第二部分会计科目名称和编号_预算会计科目_预算结余类",
                          label: "（三）预算结余类",
                        },
                      ],
                    },
                  ],
                },
                {
                  id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第三部分会计科目使用说明",
                  label: "第三部分 会计科目使用说明",
                  sub: [
                    {
                      id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第三部分会计科目使用说明_财务会计科目",
                      label: "一、财务会计科目",
                      sub: [
                        {
                          id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第三部分会计科目使用说明_财务会计科目_资产类",
                          label: "（一）资产类",
                          sub: [
                            {
                              id: "规范性文件_政府会计制度行政事业单位会计科目和报表_第三部分会计科目使用说明_财务会计科目_资产类_1001库存现金",
                              label: "1001 库存现金",
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    };
  },
  components: {
    Page,
  },
  methods: {
    menuItemClick(e, p, i) {
      var params = {
        i: "getContent",
        id: e.index,
      };
      var at = e.index.split("_");
      this.$data.currentHeader = at[at.length - 1];
      new Http(true, this.$router).request(params).then((res) => {
        this.$refs.pagePolicy.updateData(res.data);
      });
    },
  },
  mounted() {
    this.$data.sw = screen.width;
    console.error(this.$data.sw);
  },
};
</script>

<style>
* {
  padding: 0px;
  margin: 0px;
}

.el-main {
  padding: 0px !important;
}

.bottom-icon .el-icon {
  font-size: 30px !important;
}

#app {
  --el-menu-item-height: 36px;
  --el-menu-sub-item-height: 36px;
}

.el-menu-item,
.el-sub-menu__title {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  white-space: normal !important;
  word-break: break-all;
  line-height: 15px !important;
  height: auto !important;
}
</style>
