import { createRouter, createWebHashHistory } from 'vue-router'
import Page from '../views/Page.vue'

const routes = [
  // {
  //   path: '/page',
  //   name: 'page',
  //   component: Page
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
